<section class="service-section d-flex flex-wrap w-100">
    <div class="container">
        <div class="row">
            <div class="d-flex flex-wrap justify-content-center text-center title w-100">
                <h2>Features</h2>
                <p>Use the Pool app right on your mobile device</p>
            </div>
            <div class="service-wrapper padding-b30 d-flex flex-wrap w-100">
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-service></span>
                        <div class="service-content">
                            <h4>View Services</h4>
                            <p class="small">List of all services, repairs and installs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-quote></span>
                        <div class="service-content">
                            <h4>Get Quote</h4>
                            <p class="small">Select your maintenance or repair needs for a contractor quote.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-bestquote></span>
                        <div class="service-content">
                            <h4>Accept Best Quote</h4>
                            <p class="small">Select the quote that works best for you.</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-chat></span>
                        <div class="service-content">
                            <h4 class="small">Chat With Contractor</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-report></span>
                        <div class="service-content">
                            <h4>Report Issue</h4>
                            <p class="small">Submit any issue or concern.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-time></span>
                        <div class="service-content">
                            <h4>Single Time & Recurring</h4>
                            <p class="small">Schedule a one time service or recurring services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>