import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-repair]',
  template: `<svg viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.2333 14.8193C17.343 14.7559 17.792 13.7756 17.8495 12.7979H16.2324L16.2333 14.8193Z" />
  <path d="M14.0076 9.2948C13.9204 9.28903 13.8347 9.32073 13.7704 9.38255C13.7071 9.44744 13.6716 9.53627 13.6719 9.6289L13.6742 15.4931H14.3457L14.3432 9.64277C14.3431 9.55002 14.3077 9.46114 14.2446 9.39582C14.1816 9.33051 14.0963 9.29415 14.0076 9.2948Z" />
  <path d="M12.2429 8.71691L12.2458 15.4931H13.0534L13.0508 9.65325C13.0488 9.41774 13.143 9.19151 13.3118 9.02645C13.4806 8.86138 13.7095 8.77165 13.9461 8.77783C14.1832 8.77737 14.4107 8.87071 14.5786 9.03729C14.7465 9.20387 14.8409 9.43003 14.8412 9.66598L14.8437 15.4931H15.624L15.6213 8.72568C15.6201 7.52485 15.9922 6.35304 16.6863 5.37087C17.1264 4.75752 17.3448 4.01389 17.3058 3.2612C17.2509 2.05528 16.5149 0.951487 15.4351 0.405819L15.4357 3.27063C15.4357 3.34241 15.407 3.41124 15.356 3.46196C15.305 3.51269 15.2358 3.54115 15.1636 3.54108L12.6981 3.54059C12.5479 3.54059 12.426 3.41936 12.426 3.26982L12.4253 0.400024C11.5551 0.843855 10.9184 1.63849 10.6774 2.58169C10.4364 3.52488 10.6142 4.52569 11.1657 5.32939C11.8633 6.32287 12.2392 7.50484 12.2429 8.71691Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08245 14.2802C1.26546 14.2808 0.602739 14.9457 0.600098 15.7674V23.9744C0.600098 24.7978 1.26377 25.4652 2.08245 25.4652C2.90113 25.4652 3.5648 24.7978 3.5648 23.9744V15.7674C3.56216 14.9457 2.89945 14.2808 2.08245 14.2802ZM2.59454 22.2057C2.59454 22.3554 2.47387 22.4768 2.32502 22.4768C2.17617 22.4768 2.0555 22.3554 2.0555 22.2057V17.368C2.0555 17.2183 2.17617 17.097 2.32502 17.097C2.47387 17.097 2.59454 17.2183 2.59454 17.368V22.2057ZM2.32502 16.3795C2.47387 16.3795 2.59454 16.2581 2.59454 16.1084V15.7791C2.59454 15.6294 2.47387 15.5081 2.32502 15.5081C2.17617 15.5081 2.0555 15.6294 2.0555 15.7791V16.1084C2.0555 16.2581 2.17617 16.3795 2.32502 16.3795Z" />
  <path d="M4.14554 15.8223H4.14778V15.7891C4.14778 15.7846 4.14652 15.8223 4.14554 15.8223Z" />
  <path d="M14.8957 17.8756H16.7779C17.2878 17.8756 17.7012 17.4644 17.7012 16.9573C17.7012 16.4501 17.2878 16.039 16.7779 16.039H12.161C11.5155 16.0334 10.9771 15.5465 10.9104 14.9079C10.8938 14.7579 11.0006 14.622 11.151 14.6019C11.3003 14.5862 11.4342 14.6936 11.4503 14.842C11.4702 15.0307 11.5618 15.2046 11.7064 15.3282L11.7052 12.7979H9.25359C8.24114 12.7979 7.86256 13.3178 7.64583 13.7819C7.05272 15.0073 5.81674 15.7965 4.44951 15.8229H4.104V23.7635H4.88072C6.25949 23.7665 7.60982 24.1537 8.77854 24.8812C9.83688 25.5366 11.0903 25.6001 12.3891 25.6001H16.1124C16.6674 25.6001 17.1173 25.1526 17.1173 24.6007C17.1173 24.0488 16.6674 23.6014 16.1124 23.6014H14.1007C13.9507 23.6014 13.8291 23.4805 13.8291 23.3313C13.8291 23.1822 13.9507 23.0612 14.1007 23.0612H16.6955C17.2504 23.0612 17.7003 22.6138 17.7003 22.0619C17.7003 21.51 17.2504 21.0626 16.6955 21.0626H14.8934C14.7963 21.0622 14.7069 21.0103 14.6588 20.9265C14.6106 20.8428 14.6111 20.7398 14.6599 20.6564C14.7088 20.5731 14.7987 20.522 14.8957 20.5224H16.6555C17.2405 20.5224 17.7147 20.0508 17.7147 19.4691C17.7147 18.8873 17.2405 18.4157 16.6555 18.4157H14.8957C14.7457 18.4157 14.6241 18.2948 14.6241 18.1456C14.6241 17.9965 14.7457 17.8756 14.8957 17.8756Z" />
  </svg>`,
})
export class IconRepairComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}



