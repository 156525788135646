export class Contractor {
    firstName = '';
    lastName = '';
    fullName = '';
    email = '';
    password = '';
    phoneNumber = '';
    userType = 'CONTRACTOR';
    prAddressDTO = {
        addressLine: '',
        state: '',
        city: '',
        zipcode: '',
        country: '',
        latitude: '',
        longitude: ''
    };
    prBusinessInfoDTO = {
        businessName : '',
        experience: 0,
        noOfWorkers: 0,
        projectsCompleted: 0,
        servicesCategory: '',
        servicesSubCategory: '',
        notes: '',
    };
}
