import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'poolrepair';

  constructor(
    private meta: Meta
  ) {
      meta.addTag({name: 'description', content: 'Angular web app setup with routing and layout'});
  }

}
