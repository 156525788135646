import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-time]',
  template: `<svg  viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9459 12.9448H32V0L27.1463 5.10885C20.9059 -1.19994 10.8964 -1.19994 4.65593 5.10885C-1.58449 11.4176 -1.53116 21.6717 4.69149 27.9805C10.9319 34.3074 21.0659 34.3074 27.3063 27.9805C30.4354 24.817 32 20.1445 32 16.5447H28.4442C28.4442 20.1445 27.2352 22.9989 24.8173 25.4351C19.9636 30.3622 12.1053 30.3622 7.25166 25.4351C2.398 20.5081 2.398 12.563 7.25166 7.636C12.1053 2.72714 19.9636 2.90895 24.8173 7.81781L19.9459 12.9448ZM16.8879 16.8174V9.09047H14.221V18.1809L21.8304 22.7989L23.1105 20.599L16.8879 16.8174Z" fill=""/>
  </svg>
  `,
})
export class IconTimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
