import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-banner',
  templateUrl: './inner-banner.component.html',
  styleUrls: ['./inner-banner.component.scss']
})

export class InnerBannerComponent implements OnInit {
    
  @Input('PageTitle') pageTitle: any;
  
  externalId: any;

  @HostBinding('attr.id')
  set id(value: string) {
      this.externalId = null;
  } 

    constructor() { }

    ngOnInit(): void {
    }
}