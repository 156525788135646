import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2'
import { RegisterService } from 'src/app/shared/register.service';
import { CustValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  public formSubmitAttempt: boolean;
  constructor(private formBuilder: FormBuilder,
              private registerService: RegisterService) {
    this.formSubmitAttempt = false;
    this.contactForm = this.formBuilder.group({
      fullName: ['', [Validators.required, CustValidators.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required, CustValidators.noWhitespaceValidator]],
      message: ['']
    });
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  get getForm() {
    return this.contactForm.controls;
  }

  // tslint:disable-next-line: typedef
  onSubmit() {
    if (!this.formSubmitAttempt) {
      this.formSubmitAttempt = true;
      this.validateAllFormFields(this.contactForm);
    }
    if (this.contactForm.invalid) {
      return;
    } else {
      this.registerService.contactUs(this.contactForm.value).subscribe(res => {
        swal.fire('Success!', res.message, 'success');
        this.contactForm.reset();
      });
    }
  }

  // tslint:disable-next-line: typedef
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
