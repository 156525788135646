import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-default-layout.main-float',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
