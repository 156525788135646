import { Component, OnInit,Directive, Input,TemplateRef,NgZone ,ViewChild,
  ElementRef,} from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from "../../shared/data.service";
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../_modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustValidators } from 'src/app/validators/custom-validators';
import { RegisterService } from 'src/app/shared/register.service';
import { ConfirmedValidator } from 'src/app/validators/confirmed.validator';
import { Contractor } from 'src/app/shared/models/contractorRegistration.model';
import { Patterns } from 'src/app/constants/patterns';
import { Title } from '@angular/platform-browser';
import swal from 'sweetalert2'
import states from 'states-us';
import { MapsAPILoader } from "@agm/core";
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    menu: boolean = false;
    // modalRef: any = BsModalRef;
    lat: any;
    lng: any;
    myDateValue: Date;
    model: NgbDateStruct;
    eighteenYearsAgo:any = new Date();

    date: {year: number, month: number};
    place: any;
    datePickerConfig = {
      format: 'DD/MM/YYYY',
      disableKeypress:true
    };
    urlPat = '^(https?://)(http?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
    stateList: any;
    templateRefConfig = {
        backdrop: true,
        ignoreBackdropClick: false,
        keyboard: true,
        class: 'modal-dialog-centered',
      };
      signUpForm: FormGroup;

     formSubmitAttempt = false;
     contractorRegistationDTO: any;
     businessLicenseImg: any;
     taxIdentificationNoImg: any;
     liabilityInsuranceImg: any;
     stateRegistrationImg: any;
      private geoCoder:any;
      services: any;
      passwordType = 'password';
      showPassword = false;
    // openModal(template: TemplateRef<any>) {
    //     this.modalRef = this.modalService.show(template, this.templateRefConfig);
    //   }
      openModals(id: string) {
        this.modalServiceCutom.open(id);
    }

    closeModal(id: string) {
        this.modalServiceCutom.close(id);
    }
    scrollto = () => {
        let element: any = document.getElementById('textCreate');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    scroll(scrollto: any) {
        this.data.changeMessage(scrollto);
        // console.log(scrollto);
        if (this.router.url == '/business' || this.router.url == '/terms-and-conditions' || this.router.url == '/privacy-policy' || this.router.url == '/faq') {
            this.router.navigate(['/home']);
            setTimeout(() => {
                this.scrollto();
                this.menu = false;
            }, 500);
        }
        else {
            this.scrollto();
            this.menu = false;
        }
    }
    Contactscroll(scrollto: any) {
        this.data.changeMessage(scrollto);
        // console.log(scrollto);
        let element: any = document.getElementById('contact');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // Businessscroll(scrollto: any) {
    //     this.data.changeMessage(scrollto);
    //     console.log(scrollto);
    //     let element: any = document.getElementById('business');
    //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
     getFormattedDate(date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');

      return year  + '-' + month  + '-' + day;
  }
    constructor(private formBuilder: FormBuilder, private titleService: Title,
        private data: DataService,
        private modalServiceCutom: ModalService,
        private router: Router,
        private loaderService: NgxSpinnerService,
        // private modalService: BsModalService,
        private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
        private registrationService: RegisterService) {

          this.myDateValue = new Date();
          this.eighteenYearsAgo.setTime(this.eighteenYearsAgo.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000);
          // this.eighteenYearsAgo = this.getFormattedDate(this.eighteenYearsAgo);
          // console.log(this.eighteenYearsAgo,"data")
        this.signUpForm = this.formBuilder.group({
          firstName: ['', [Validators.required, CustValidators.noWhitespaceValidator]],
          lastName: ['', [Validators.required, CustValidators.noWhitespaceValidator]],
          phoneNumber: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10), Validators.pattern(Patterns.phoneNumber)]],
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required, Validators.pattern(Patterns.password)]],
          confirmPassword: ['', [Validators.required, Validators.pattern(Patterns.password)]],
          address: ['', [Validators.required]],
          state: ['', [Validators.required]],
          city: ['', [Validators.required]],
          dob: ['', [Validators.required, Validators.pattern(Patterns.date)]],
          zipcode: ['', [Validators.required]],
          country: ['', [Validators.required]],
          ssn: ['', [Validators.required , Validators.minLength(4),
            Validators.maxLength(4)]],
          businessName: [''],
          // businessUrl: [''],
          businessUrl:  ['', [Validators.required]],
          businessExp: [''],
          noOfWorker: ['', Validators.required],
          noOfProjectsCmplt: ['', Validators.required],
          selectedServices: [''],
          notes: [''],
          termsAndCondition: ['', Validators.required]
        }, { validator: ConfirmedValidator('password', 'confirmPassword') });
        this.contractorRegistationDTO = new Contractor();
      }
      @ViewChild("search", {
        static: true,
      })
      public searchElementRef: ElementRef
      ngAfterViewInit(): void {


    }
    ngOnInit() {
      // console.log("afterinit");

        this.stateList = states;


        this.registrationService.getServicesWithoutpagination().subscribe(res => {
          this.services = [];
          if (res.data.length > 0) {
            res.data.forEach((service: any) => {
              if (service.prSubServices.length > 0) {
                service.prSubServices.forEach((subService: any) => {
                  this.services.push({
                    groupName: service.name,
                    prServiceId: subService.prServiceId,
                    id: subService.id,
                    value: subService.subCategoryName,
                  });
                });
              }
            });
          }
        });
        this.mapsAPILoader.load().then(() => {
          this.geoCoder = new google.maps.Geocoder();
          let autocomplete = new google.maps.places.Autocomplete(
            this.searchElementRef.nativeElement,
            {
              types: ["address"],
            }
          );
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              //get the place result
              let places: google.maps.places.PlaceResult = autocomplete.getPlace();
              let place:any =places;
              this.signUpForm.patchValue({
                zipcode: "",
              });
              // console.log(place,"datas");
              this.place = place.formatted_address;

              for (var i = 0; i <  place?.address_components.length; i++) {
                var addressType = place?.address_components[i].types[0];
                if (addressType == "postal_code") {
                  this.signUpForm.patchValue({
                    zipcode: place?.address_components[i].long_name,
                  });
                  this.signUpForm.controls['zipcode'].disable();
                }
                else{
                  this.signUpForm.controls['zipcode'].enable();
                }
                if (addressType == "administrative_area_level_1") {
                  this.signUpForm.patchValue({
                    state: place?.address_components[i].short_name,
                  });
                  this.signUpForm.controls['state'].disable();

                }
                if (addressType == "country") {
                  this.signUpForm.patchValue({
                    country: place?.address_components[i].short_name,
                  });
                  this.signUpForm.controls['country'].disable();
                }
                  if (addressType == "locality") {
                    this.signUpForm.patchValue({
                      city: place?.address_components[i].long_name,
                    });
                    this.signUpForm.controls['city'].disable();
                }

            }
              //verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              // console.log(this.place);
              this.place = place.formatted_address;
              this.signUpForm.patchValue({
                address: this.place,
              });

              //set latitude, longitude and zoom
              this.lat = place.geometry.location.lat();
              this.lng = place.geometry.location.lng();

            });
          });
        });


    }

   async onSubmit() {

      // console.log(this.signUpForm.value.dob
      //           ,"dob",this.signUpForm )
        if (!this.formSubmitAttempt) {
          this.formSubmitAttempt = true;
          this.validateAllFormFields(this.signUpForm);
        }
        if (this.signUpForm.invalid) {
          document.getElementById("HedingLogin")?.scrollIntoView({behavior: 'smooth'});
          return
        } else {
          var dobDate = new Date(this.signUpForm.value.dob);
          if(isNaN(dobDate.valueOf())){
            swal.fire('Date Of Birth Error!','Please Enter Valid Date Of Birth' , 'error');
            return;
          }
          else{
            if(dobDate.getFullYear()>this.eighteenYearsAgo.getFullYear()){
            swal.fire('Date Of Birth Error!','Minimum age requirements is 18' , 'error');
            return;
            }
          }
          // if(!this.lat || !this.lng){
          //  await this.registrationService.getPosition().then(pos=>
          //     {
          //       this.lng =pos.lng
          //       this.lat = pos.lat
          //        console.log(`Positon: ${pos.lng} ${pos.lat}`);
          //     });
          // }

          this.contractorRegistationDTO.firstName = this.signUpForm.value.firstName;
          this.contractorRegistationDTO.lastName = this.signUpForm.value.lastName;
          this.contractorRegistationDTO.fullName = this.signUpForm.value.firstName + ' ' + this.signUpForm.value.lastName;
          this.contractorRegistationDTO.email = this.signUpForm.value.email;
          this.contractorRegistationDTO.password = this.signUpForm.value.password;
          // this.contractorRegistationDTO.dobDay = parseInt(this.signUpForm.value.dob.day);
          // this.contractorRegistationDTO.dobMonth = parseInt(this.signUpForm.value.dob.month);
          // this.contractorRegistationDTO.dobYear = parseInt(this.signUpForm.value.dob.year);
         if(dobDate.getMonth() == 0){
          this.contractorRegistationDTO.dobMonth = 1;
         }else{
          this.contractorRegistationDTO.dobMonth = dobDate.getMonth();
         }
          this.contractorRegistationDTO.dobDay = dobDate.getDate();
          this.contractorRegistationDTO.dobYear = dobDate.getFullYear();
          this.contractorRegistationDTO.phoneNumber = this.signUpForm.value.phoneNumber;
          this.contractorRegistationDTO.prAddressDTO.latitude = this.lat;
          this.contractorRegistationDTO.prAddressDTO.longitude = this.lng;
          this.contractorRegistationDTO.prAddressDTO.addressLine1 = this.signUpForm.value.address;
          this.contractorRegistationDTO.prAddressDTO.addressLine2 = this.signUpForm.value.address;
          this.contractorRegistationDTO.addressLine1 = this.signUpForm.value.address;
          this.contractorRegistationDTO.addressLine2 = this.signUpForm.value.address;
          this.contractorRegistationDTO.prAddressDTO.state = this.signUpForm.controls.state.value;
          this.contractorRegistationDTO.prAddressDTO.city = this.signUpForm.controls.city.value;
          this.contractorRegistationDTO.prAddressDTO.postalCode = this.signUpForm.controls.zipcode.value;
          this.contractorRegistationDTO.prAddressDTO.country = this.signUpForm.controls.country.value;
          this.contractorRegistationDTO.prAddressDTO.addressLine = this.signUpForm.value.address;
          this.contractorRegistationDTO.stateIso = this.signUpForm.controls.state.value;
          this.contractorRegistationDTO.city = this.signUpForm.controls.city.value;
          this.contractorRegistationDTO.postalCode = this.signUpForm.controls.zipcode.value;
          this.contractorRegistationDTO.countryIso = this.signUpForm.controls.country.value;
          this.contractorRegistationDTO.ssn = this.signUpForm.value.ssn;
          this.contractorRegistationDTO.businessUrl = this.signUpForm.value.businessUrl;
          this.contractorRegistationDTO.prBusinessInfoDTO.businessName = this.signUpForm.value.businessName;
          this.contractorRegistationDTO.prBusinessInfoDTO.experience = this.signUpForm.value.businessExp;
          this.contractorRegistationDTO.prBusinessInfoDTO.noOfWorkers = this.signUpForm.value.noOfWorker;
          this.contractorRegistationDTO.prBusinessInfoDTO.projectsCompleted = this.signUpForm.value.noOfProjectsCmplt;
          this.contractorRegistationDTO.prBusinessInfoDTO.servicesCategory = this.getServicesCategory(this.signUpForm.value.selectedServices);
          this.contractorRegistationDTO.prBusinessInfoDTO.servicesSubCategory = this.getSubServices(this.signUpForm.value.selectedServices);
          this.contractorRegistationDTO.prBusinessInfoDTO.notes = this.signUpForm.value.notes;
          // console.log(this.contractorRegistationDTO,"data");


          const formData: FormData = new FormData();
          formData.append('data', new Blob([JSON.stringify(this.contractorRegistationDTO)], { type: 'application/json' }));
          formData.append('businessLicense', this.businessLicenseImg, this.businessLicenseImg.name);
          formData.append('taxIdentificationNumber', this.taxIdentificationNoImg, this.taxIdentificationNoImg.name);
          formData.append('liabilityInsurance', this.liabilityInsuranceImg, this.liabilityInsuranceImg.name);
          formData.append('stateRegistration', this.stateRegistrationImg, this.stateRegistrationImg.name);
          this.registrationService.registerContractor(formData).subscribe(res => {
            this.closeModal('custom-modal-1');
            swal.fire('Success!', res.message, 'success');
            this.signUpForm.reset();
            this.businessLicenseImg = null;
            this.taxIdentificationNoImg = null;
            this.liabilityInsuranceImg = null;
            this.stateRegistrationImg = null;
            this.formSubmitAttempt = false;
          });
        }
      }
      get getForm() {
        return this.signUpForm.controls;
      }
      getServicesCategory(service: any) {
        let selectedServices = '';
        service.forEach((element: string) => {
          this.services.forEach((item: any) => {
            if (item.value === element) {
              if (selectedServices) {
                if (selectedServices.indexOf(item.prServiceId) == -1){
                  selectedServices = selectedServices + ',' + item.prServiceId;
              }
            }
            else {
              selectedServices = item.prServiceId + '';
            }
          }
          });
      });
        return selectedServices;
      }

    getSubServices(service: any) {
      let selectedServices = '';
      service.forEach((element: string) => {
        this.services.forEach((item: any) => {
          if (item.value === element) {
            if (selectedServices) {
              if (selectedServices.indexOf(item.id) == -1){
                selectedServices = selectedServices + ',' + item.id;
            }
          }
          else {
            selectedServices = item.id + '';
          }
        }
        });
    });
      return selectedServices;
    }
    onFileChanged(event: any, type: string, element:HTMLInputElement) {
        switch (type) {
          case 'business':
            this.businessLicenseImg = event.target.files[0];
            element.value = ""
            break;

          case 'tax':
            this.taxIdentificationNoImg = event.target.files[0];
            element.value = ""
            break;

          case 'liablity':
            this.liabilityInsuranceImg = event.target.files[0];
            element.value = ""
            break;

          case 'state':
            this.stateRegistrationImg = event.target.files[0];
            element.value = ""
            break;
        }
      }
      validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
          }
        });
      }
      toggleShow() {
        this.showPassword = !this.showPassword;
        this.passwordType = this.showPassword ? 'text' : 'password';
      }
}