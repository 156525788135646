import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannerComponent } from '../component/banner/banner.component';
import { ServiceComponent } from '../component/service/service.component';
import { IconServiceComponent } from '../svg/icon-service.component';
import { IconQuoteComponent } from '../svg/icon-quote.component';
import { IconBestQuoteComponent } from '../svg/icon-bestquote.component';
import { IconChatComponent } from '../svg/icon-chat.component';
import { IconTimeComponent } from '../svg/icon-time.component';
import { IconReportComponent} from '../svg/icon-report.component';
import { IconPlayComponent } from '../svg/icon-play.component';
import { IconRepairComponent } from '../svg/icon-repair.component';
import { BusinessServiceComponent } from "../component/business-service/business-service.component";
import { IconPaymentComponent } from '../svg/icon-payment.component';
import { ContactComponent } from '../component/contact/contact.component';
import { InnerBannerComponent } from '../component/inner-banner/inner-banner.component';
import { IconUploadComponent } from '../svg/icon-upload.component';
import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
    declarations: [
        BannerComponent,
        ServiceComponent,
        IconChatComponent,
        IconTimeComponent,
        IconQuoteComponent,
        IconServiceComponent,
        IconBestQuoteComponent,
        IconReportComponent,
        IconPlayComponent,
        IconRepairComponent,
        BusinessServiceComponent,
        IconPaymentComponent,
        ContactComponent,
        InnerBannerComponent,
        IconUploadComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
    ],
    exports: [
        BannerComponent,
        ServiceComponent,
        IconChatComponent,
        IconTimeComponent,
        IconQuoteComponent,
        IconServiceComponent,
        IconBestQuoteComponent,
        IconReportComponent,
        IconPlayComponent,
        IconRepairComponent,
        BusinessServiceComponent,
        IconPaymentComponent,
        ContactComponent,
        InnerBannerComponent,
        IconUploadComponent,
        NgxSpinnerModule,
    ]
})
export class AppCommonModule { }
