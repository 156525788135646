<section class="service-section d-flex flex-wrap w-100">
    <div class="container">
        <div class="row">
            <div class="d-flex flex-wrap justify-content-center text-center title w-100">
                <h2>Features</h2>
                <p>Use the Pool app right on your mobile device</p>
            </div>
            <div class="service-wrapper padding-b30 d-flex flex-wrap w-100">
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-quote></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Send Quote</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-bestquote></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Receive Quote</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-service></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Manage Order</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-chat></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Chat With Customer</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-report></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Manage Service</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="features-box">
                        <span class="icon icon32_32 icon_blue" icon-payment></span>
                        <div class="service-content">
                            <h4 style="padding: 8px;" class="small">Request Payment</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>