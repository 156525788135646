import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-quote]',
  template: `<svg viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8311 3.14286H21.7778C23.4889 3.14286 24.8889 4.55714 24.8889 6.28571V15.2114C23.9244 14.7557 22.8822 14.4257 21.7778 14.2686V6.28571H18.6667V11H6.22222V6.28571H3.11111V29.8571H10.4067C10.9511 31.0357 11.6978 32.1043 12.6156 33H3.11111C1.4 33 0 31.5857 0 29.8571V6.28571C0 4.55714 1.4 3.14286 3.11111 3.14286H8.05778C8.71111 1.32 10.4222 0 12.4444 0C14.4667 0 16.1778 1.32 16.8311 3.14286ZM20.2222 17.2857C15.9289 17.2857 12.4444 20.8057 12.4444 25.1429C12.4444 29.48 15.9289 33 20.2222 33C24.5156 33 28 29.48 28 25.1429C28 20.8057 24.5156 17.2857 20.2222 17.2857ZM22.7889 28.8357L19.4444 25.4571V20.4286H21V24.8129L23.8778 27.72L22.7889 28.8357Z" fill=""/>
  </svg>`,
})
export class IconQuoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
