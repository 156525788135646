import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-payment]',
  template: `<svg viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7 0H3.3C1.4685 0 0.0165 1.45909 0.0165 3.27885L0 22.9519C0 24.7717 1.4685 26.2308 3.3 26.2308H29.7C31.5315 26.2308 33 24.7717 33 22.9519V3.27885C33 1.45909 31.5315 0 29.7 0ZM29.7 22.9519H3.3V13.1154H29.7V22.9519ZM3.3 6.55769H29.7V3.27885H3.3V6.55769Z" fill=""/>
  </svg>`,
})
export class IconPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}





