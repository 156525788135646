import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit {

  constructor() { }

  @Input('Title') title: any; 
  @Input('BannerDescription') bannerdescription: any;
  @Input('SubTitle') subtitle: any;
  @Input('SubTitle2') subtitle2:any;
  externalId: any;
  
  @HostBinding('attr.id')
  set id(value: string) {
      this.externalId = null;
  } 

  ngOnInit(): void {
  }

}
