import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-report]',
  template: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3383 0H9.66167L0 9.66167V23.3383L9.66167 33H23.3383L33 23.3383V9.66167L23.3383 0ZM16.5 26.2167C15.18 26.2167 14.1167 25.1533 14.1167 23.8333C14.1167 22.5133 15.18 21.45 16.5 21.45C17.82 21.45 18.8833 22.5133 18.8833 23.8333C18.8833 25.1533 17.82 26.2167 16.5 26.2167ZM14.6667 18.3333H18.3333V7.33333H14.6667V18.3333Z" fill=""/>
  </svg>
  `,
})
export class IconReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
