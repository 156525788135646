import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { AppCommonModule } from './shared/app-common.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptor } from './shared/http-interceptor.service';
import { LoaderInterceptorService } from './shared/loader-interceptor.service';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { AgmCoreModule } from "@agm/core";
import { ModalModule } from './layout/_modal';
import {DpDatePickerModule} from 'ng2-date-picker';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask'

// export const options: Partial<IConfig> | (() => Partial<IConfig>) = string;
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    observer: true,
};
@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        HeaderComponent,
        FooterComponent,
        AuthLayoutComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppCommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserAnimationsModule,

            NgxMaskModule.forRoot(),

        NgSelectModule,
        NgxSpinnerModule,
        SwiperModule,
        ToastrModule.forRoot(),
        BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
        FormsModule,
        DpDatePickerModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
          // please get your own API key here:
          // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
          apiKey: 'AIzaSyCn_yaup-fgkmTgbRL8iV3oJKl_pYxTRO0',
          libraries: ["places"],
        }),
        // ModalModule.forRoot()
         ModalModule,

        ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },

    {
        provide: SWIPER_CONFIG,
        useValue: DEFAULT_SWIPER_CONFIG
    }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
