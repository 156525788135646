import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-play]',
  template: `<svg viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle opacity="0.082101" cx="42.5" cy="42.5" r="42.5" fill="white"/>
  <circle opacity="0.168387" cx="42.5" cy="42.5" r="38.5" fill="white"/>
  <path d="M76 42.5C76 61.0015 61.0015 76 42.5 76C23.9985 76 9 61.0015 9 42.5C9 23.9985 23.9985 9 42.5 9C61.0015 9 76 23.9985 76 42.5Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M52 43L34 52V34L52 43Z" fill="#191919"/>
  </svg>`,
})
export class IconPlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}



