import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, retry, debounceTime } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private spinner: NgxSpinnerService,
        private toastr: ToastrService
    ) { }

    // intercept(
    //     request: HttpRequest<any>,
    //     next: HttpHandler
    // ): Observable<HttpEvent<any>> {
    //   return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
    //         let errorMessage = '';
            
    //         if (error.error?.detail) {
    //             this.toastr.error(error.error?.detail);
    //           } else if (error.error?.title) {
    //             this.toastr.error(error.error?.title);
    //           }
    //         errorMessage = `${error.error?.message || error.message}`;
    //         console.log(error,errorMessage)
    //         return throwError(new Error(errorMessage));
    //       }));
    // }
    intercept(request: HttpRequest<any>, next: HttpHandler): any {
      return next.handle(request).pipe(
        tap(
          event => { },
          error => {
            if (error instanceof HttpErrorResponse) {
              if (error.error?.detail) {
                this.toastr.error(error.error?.detail);
              } else if (error.error?.title) {
                this.toastr.error(error.error?.title);
              }            
            }
          }
        ));
  
    }
}
