import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-chat]',
  template: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.3 0H29.7C31.515 0 33 1.485 33 3.3V23.1C33 24.915 31.515 26.4 29.7 26.4H6.6L0 33L0.0165 3.3C0.0165 1.485 1.485 0 3.3 0ZM8.25 14.85H11.55V11.55H8.25V14.85ZM18.15 14.85H14.85V11.55H18.15V14.85ZM21.45 14.85H24.75V11.55H21.45V14.85Z" fill=""/>
  </svg>`,
})
export class IconChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
