<section id="contact" class="contact-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="contact-wrapper">
          <div class="title">
            <h2>Contact</h2>
          </div>
          <div class="row">
            <div class="col left">
              <div class="maps">
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212270.7411321579!2d-84.56068880457461!3d33.767633772689834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045d6993098d%3A0x66fede2f990b630b!2sAtlanta%2C%20GA%2C%20USA!5e0!3m2!1sen!2sin!4v1651734686707!5m2!1sen!2sin"
                  width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                  tabindex="0"></iframe>
              </div>
              <div class="contact-detail">
                <ul>
                  <!-- <li>
                    <label>Address</label>
                    <address>
                      1174 Locust View Drive, Canonsburg, PA, US, 15317
                    </address>
                  </li>
                  <li>
                    <label>Phone Number</label>
                    <a href="tel:+14707725053">+1 470 772 5053,</a>
                    <a href="tel:+254 74335366">+254 74335366</a>
                  </li> -->
                  <li>
                    <label>Email</label>
                    <a href="emailto:easybreezypools@gmail.com">easybreezypools@gmail.com</a>
                    <!-- <a href="emailto:poolrepairing@gmail.com">poolrepairing@gmail.com</a> -->
                  </li>
                </ul>
              </div>
            </div>
            <div class="col right">
              <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contact-form d-flex flex-wrap w-100">
                <ul>
                  <li>
                    <div class="form-group">
                      <label>Full Name</label>
                      <input type="text" maxlength="20" formControlName="fullName" class="input-group" required
                        [ngClass]="{ 'is-invalid':getForm.fullName.touched && formSubmitAttempt && getForm.fullName.errors }">
                      <div *ngIf="getForm.fullName.errors && getForm.fullName.touched && formSubmitAttempt"
                        class="invalid-feedback">
                        <div *ngIf="getForm.fullName.errors.required || contactForm.hasError('whitespace',['fullName'])">Full name is required</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="input-group" formControlName="email" required maxlength="30"
                        [ngClass]="{ 'is-invalid': getForm.email.touched && formSubmitAttempt && getForm.email.errors }" />
                      <div *ngIf="getForm.email.errors && getForm.email.touched && formSubmitAttempt"
                        class="invalid-feedback">
                        <div *ngIf="getForm.email.errors.required ">Email is required</div>
                        <div *ngIf="getForm.email.errors.email">Please enter a valid email address</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-group">
                      <label>Subject</label>
                      <input type="text" formControlName="subject" class="input-group" required maxlength="100"
                        [ngClass]="{ 'is-invalid':getForm.subject.touched && formSubmitAttempt && getForm.subject.errors }">
                      <div *ngIf="getForm.subject.errors && getForm.subject.touched && formSubmitAttempt"
                        class="invalid-feedback">
                        <div *ngIf="getForm.subject.errors.required || contactForm.hasError('whitespace',['subject'])">Subject is required</div>
                        <div *ngIf="getForm.subject.errors.minlength">Subject must be at least 20 characters</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea type="text" formControlName="message" maxlength="100" class="input-group"
                        style="resize: none;"></textarea>
                    </div>
                  </li>
                  <li style="width: auto;">
                    <div class="form-group">
                      <button type="submit" class="btn">Send</button>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div class="nav-link">
          <ul>
            <li>
              <a href="#/faq">FAQ</a>
            </li>
            <li>
              <a href="#/terms-and-conditions">Terms and Conditions</a>
            </li>
            <li>
              <a href="#/privacy-policy">Privacy Policy </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>