import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[icon-bestquote]',
  template: `<svg  viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.75 3.14286H16.8096C16.1571 1.32 14.4482 0 12.4286 0C10.4089 0 8.7 1.32 8.0475 3.14286H3.10714C1.39821 3.14286 0 4.55714 0 6.28571V29.8571C0 31.5857 1.39821 33 3.10714 33H12.5995C11.6829 32.1043 10.9371 31.0357 10.3934 29.8571H3.10714V6.28571H6.21429V11H18.6429V6.28571H21.75V14.2686C22.853 14.4257 23.8939 14.7557 24.8571 15.2114V6.28571C24.8571 4.55714 23.4589 3.14286 21.75 3.14286Z" fill=""/>
  <path d="M20.0793 30.5288L14.5 25.021L16.7223 22.8273L20.0636 26.1257L26.7462 19.5288L29 21.7226L20.0793 30.5288Z" fill=""/>
  </svg>
  `,
})
export class IconBestQuoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
