<section class="banner-section">
    <div class="container">
        <div class="row align-items-center ">
            <div class="col-md-6">
                <div class="banner-content">
                    <h1>{{title}}</h1>
                    <p>{{bannerdescription}}</p>
                    <p>{{subtitle}}</p>
                    <p>{{subtitle2}}</p>
                    <div class="download-app">
                        <h5>Download App For</h5>
                        <ul class="d-flex flex-wrap w-100">
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon-apple.svg"
                                        alt="icon-apple"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon-andoid.svg"
                                        alt="icon-android"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="banner-image d-flex flex-wrap justify-content-end">
                    <figure>
                        <img src="assets/images/mobile_screen.png" alt="banner image">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</section>
