<div class="container">
  <div class="header-main">
      <div class="logo">
          <a [routerLink]="['/home']">
              <img src="assets/images/logo.svg" alt="logo">
          </a>
      </div>
      <div class="d-flex align-items-center flex-wrap">
          <nav [ngClass]=" menu ? 'menu-active' : ''" class="navigation-bar">
              <ul>
                  <li>
                      <a (click)="menu = !menu" [routerLink]="['/home']" routerLinkActive="active">
                          Home
                      </a>
                  </li>
                  <li>
                      <a href="javascript:void(0)" (click)="scroll('service')">
                          Service
                      </a>
                  </li>
                  <li>
                      <a (click)="menu = !menu" [routerLink]="['/business']" routerLinkActive="active">
                          Business
                      </a>
                  </li>
              </ul>
          </nav>
          <div class="contact">
              <button class="btn" id="headerformReg" (click)="openModals('custom-modal-1')"
                >Business Registration</button>
              <!-- (click)="Businessscroll('business')" -->
          </div>
          <div class="contact">
              <button class="btn contact-mobile" (click)="Contactscroll('contact')">Contact</button>
          </div>
          <a [ngClass]=" menu ? 'active' : ''" class="hb-menu" (click)="menu = !menu" href="javascript:void(0)">
              <span></span>
          </a>
      </div>
      <!-- <input type="text" #search> -->
  </div>
</div>
<div class="modal fade" id="tmc" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
aria-hidden="true" data-keyboard="false" data-backdrop="static">
<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-body form-popup">


  </div>
  </div>
</div>
</div>
<custom-modal id="custom-modal-1" class="business-modal">
  <div class="modal-body form-popup">
    <button type="button" class="close pull-right close-btn" aria-label="Close" (click)="closeModal('custom-modal-1')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="popup-top">
      <div class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </div>
      <div class="content">
        <h2>Sign Up</h2>
        <p>Sign up to create your business profile</p>
      </div>
    </div>
    <form class="signup-form" [formGroup]="signUpForm" enctype="multipart/form-data">
      <div class="signup-part">
        <h4 id="HedingLogin">Contact Information</h4>
        <ul class="signup-info">
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" formControlName="firstName" placeholder="floating-input"
                maxlength="20"
                [ngClass]="{ 'is-invalid': getForm.firstName.touched && formSubmitAttempt && getForm.firstName.errors }">
              <label>First Name</label>
              <div *ngIf="getForm.firstName.errors && getForm.firstName.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.firstName.errors.required || signUpForm.hasError('whitespace',['firstName'])">First
                  name is required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" formControlName="lastName" placeholder="floating-input"
                maxlength="20"
                [ngClass]="{ 'is-invalid': getForm.lastName.touched && formSubmitAttempt && getForm.lastName.errors }">
              <label>Last Name</label>
              <div *ngIf="getForm.lastName.errors && getForm.lastName.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.lastName.errors.required || signUpForm.hasError('whitespace',['lastName'])">Last
                  name is required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="email" class="floating-input" formControlName="email" maxlength="30"
                placeholder="floating-input"
                [ngClass]="{ 'is-invalid': getForm.email.touched && getForm.email.errors }">
              <label>Email Address</label>
              <div *ngIf="getForm.email.errors && getForm.email.touched" class="invalid-feedback">
                <div *ngIf="getForm.email.errors.required">Email is required</div>
                <div *ngIf="getForm.email.errors.email">Please enter a valid email address</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" minlength="10" maxlength="16" digitOnly formControlName="phoneNumber"
                placeholder="floating-input" mask="(000) 000 - 0000"
                [ngClass]="{ 'is-invalid': getForm.phoneNumber.touched && formSubmitAttempt && getForm.phoneNumber.errors }" />
              <label>Phone No</label>
              <div *ngIf="getForm.phoneNumber.errors && getForm.phoneNumber.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.phoneNumber.errors.required">Phone number is required</div>
                <div *ngIf="getForm.phoneNumber.errors.minlength">Phone number must be 10 digits long</div>
                <div *ngIf="!getForm.phoneNumber.errors.minlength && getForm.phoneNumber.errors.pattern">Please enter valid phone number</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input [type]="passwordType" class="floating-input" maxlength="30" formControlName="password"
                placeholder="floating-input"
                [ngClass]="{ 'is-invalid': getForm.password.touched && getForm.password.errors }" />
              <label>Password</label>
              <button (click)="toggleShow()" class="password-show-hide">
                  <i class="fa fa-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                  <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
                  </button>
              <div *ngIf="getForm.password.errors && getForm.password.touched"
                class="invalid-feedback">
                <div *ngIf="getForm.password.errors.required">Password is required</div>
                <div *ngIf="getForm.password.errors.pattern">Password should have minimum 8 characters, at least
                  1 uppercase letter, 1 lowercase letter and 1 number</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="password" class="floating-input" maxlength="30" formControlName="confirmPassword"
                placeholder="floating-input"
                [ngClass]="{ 'is-invalid': getForm.confirmPassword.touched && getForm.confirmPassword.errors }">
              <label>Re-enter Password</label>
              <div *ngIf="getForm.confirmPassword.errors && getForm.confirmPassword.touched"
                class="invalid-feedback">
                <div *ngIf="getForm.confirmPassword.errors.required">Password is required</div>
                <div *ngIf="getForm.confirmPassword.errors.pattern">Password should have minimum 8 characters, at
                  least
                  1 uppercase letter, 1 lowercase
                  letter and 1 number</div>
                <div *ngIf="getForm.confirmPassword.errors.confirmedValidator">Password and re-enter password must
                  be
                  match.</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" minlength="4" maxlength="4" digitOnly formControlName="ssn"
                placeholder="floating-input"
                [ngClass]="{ 'is-invalid': getForm.ssn.touched && formSubmitAttempt && getForm.ssn.errors }">
              <label>SSN(last 4 digits)</label>
              <div *ngIf="getForm.ssn.errors && getForm.ssn.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.ssn.errors.required">SSN number is required</div>
                <div *ngIf="getForm.ssn.errors.minlength">SSN number must be 4 digits long</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <!-- <ngx-dates-picker formControlName="dob"
              class="floating-input" placeholder="floating-input"></ngx-dates-picker> -->
              <!-- <input type="text" class="floating-input" placeholder="floating-input"
                 bsDatepicker> -->
                <input type="tel" formControlName="dob"
                class="floating-input" placeholder="floating-input"
                 mask="M0/d0/0000" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
                <!-- <input class="floating-input" placeholder="yyyy-mm-dd"
                formControlName="dob" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()"> -->
                <!-- <input type="date"   class="floating-input" placeholder="floating-input"
                 formControlName="dob"
                [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" max="{{eighteenYearsAgo}}"  > -->
                <!-- <dp-date-picker theme="dp-material"
                        [config]="datePickerConfig"
                        formControlName="dob" class="floating-input">
              </dp-date-picker> -->

              <label>Date of Birth(mm/dd/yyyy)</label>
              <div *ngIf="getForm.dob.errors && getForm.dob.touched && formSubmitAttempt" class="invalid-feedback">
                <div *ngIf="getForm.dob.errors.required">Date of Birth is required
                </div>
                <div *ngIf="getForm.dob.errors.pattern">Please enter valid date.</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" placeholder="floating-input"
                formControlName="address" #search >
              <label>Address</label>
              <div *ngIf="getForm.address.errors && getForm.address.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.address.errors.required">Address is
                  required</div>
              </div>
            </div>

          </li>
          <li>
            <div class="form-group">
              <!-- <select name="cars" class="floating-input" id="cars" formControlName="state">
                <option *ngFor="let state of stateList" [ngValue]="state.name">
                  {{state.name}} </option>
              </select> -->
              <input type="text" class="floating-input" placeholder="floating-input" maxlength="20"
              formControlName="state">
              <label>State</label>
              <div *ngIf="getForm.state.errors && getForm.state.touched && formSubmitAttempt" class="invalid-feedback">
                <div *ngIf="getForm.state.errors.required || signUpForm.hasError('whitespace',['state'])">State is
                  required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" placeholder="floating-input"
                formControlName="city">
              <label>City</label>
              <div *ngIf="getForm.city.errors && getForm.city.touched && formSubmitAttempt" class="invalid-feedback">
                <div *ngIf="getForm.city.errors.required || signUpForm.hasError('whitespace',['city'])">City is required
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="number" class="floating-input" maxlength="5" digitOnly placeholder="floating-input"
                formControlName="zipcode">
              <label>Zipcode</label>
              <div *ngIf="getForm.zipcode.errors && getForm.zipcode.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.zipcode.errors.required || signUpForm.hasError('whitespace',['zipcode'])">Zipcode is
                  required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" maxlength="20" placeholder="floating-input"
                formControlName="country">
              <label>Country</label>
              <div *ngIf="getForm.country.errors && getForm.country.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.country.errors.required || signUpForm.hasError('whitespace',['country'])">Country is
                  required</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="signup-part">
        <h4>Business Information</h4>
        <ul class="signup-info">
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" placeholder="floating-input" maxlength="20"
                formControlName="businessName">
              <label>Business Name (If Any)</label>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" placeholder="floating-input"
                formControlName="businessUrl" (keydown.space)="$event.preventDefault();">
              <label>Business Url</label>
            </div>
            <div *ngIf="getForm.businessUrl.errors && getForm.businessUrl.touched && formSubmitAttempt"
            class="invalid-feedback">
            <div *ngIf="getForm.businessUrl.errors.required">Business Url is required</div>
            <div *ngIf="getForm.businessUrl.errors.pattern">Business Url should have valid url</div>
            </div>

          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input"  placeholder="floating-input"
                formControlName="businessExp">
              <label>Business Experience (If Any)</label>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="number" class="floating-input" maxlength="4" digitOnly placeholder="floating-input"
                formControlName="noOfWorker">
              <label>Number Of Workers</label>
              <div *ngIf="getForm.noOfWorker.errors && getForm.noOfWorker.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.noOfWorker.errors.required">Number of workers is required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="number" class="floating-input" digitOnly placeholder="floating-input"
                formControlName="noOfProjectsCmplt" maxlength="3">
              <label>Number Of Projects Completed</label>
              <div *ngIf="getForm.noOfProjectsCmplt.errors && getForm.noOfProjectsCmplt.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.noOfProjectsCmplt.errors.required">Number of projects completed is required</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <ng-select class="floating-input" [items]="services" [multiple]="true" bindLabel="value" groupBy="groupName"
                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false" bindValue="value"
                formControlName="selectedServices" placeholder="Select Services" [searchable]="false">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                    [ngModelOptions]="{standalone: true}" />
                  {{item.groupName}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="check-{{index}}" type="checkbox" [ngModel]="item$.selected"
                    [ngModelOptions]="{standalone: true}" />
                  {{item.value}}
                </ng-template>
              </ng-select>

              <div *ngIf="getForm.selectedServices.errors && getForm.selectedServices.touched && formSubmitAttempt"
                class="invalid-feedback">
                <div *ngIf="getForm.selectedServices.errors.required">Please select atleast one service</div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <input type="text" class="floating-input" placeholder="floating-input" maxlength="100"
                formControlName="notes">
              <label>Notes(if Any)</label>
            </div>
          </li>
          <li>
            <p>Upload your business documents and project images</p>
          </li>
          <li>
            <div class="form-group">
              <div class="project-checkbox">
                <span *ngIf="businessLicenseImg">
                  <button (click)="businessLicenseImg = null"></button>
                </span>
                <input type="file" id="file12" accept="image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" (change)="onFileChanged($event, 'business', inputFile1)" [disabled]="businessLicenseImg" #inputFile1>
                <label for="file12" [hidden]="businessLicenseImg">
                  <i class="icon icon24_24" icon-upload></i>
                  Business License
                </label>
                <label for="file12" *ngIf="businessLicenseImg">
                  {{businessLicenseImg.name}}
                </label>
                <div *ngIf="!businessLicenseImg && formSubmitAttempt" class="invalid-feedback">
                  <div>Please upload business license image</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <div class="project-checkbox">
                <span *ngIf="taxIdentificationNoImg">
                  <button (click)="taxIdentificationNoImg = null"></button>
                </span>
                <input type="file" id="file15" accept="image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" (change)="onFileChanged($event, 'tax', inputFile2)" [disabled]="taxIdentificationNoImg" #inputFile2>
                <label for="file15" [hidden]="taxIdentificationNoImg">
                  <i class="icon icon24_24" icon-upload></i>
                  Tax Identification Number
                </label>
                <label for="file15" *ngIf="taxIdentificationNoImg">
                  {{taxIdentificationNoImg.name}}
                </label>
                <div *ngIf="!taxIdentificationNoImg && formSubmitAttempt" class="invalid-feedback">
                  <div>Please upload tax identification number image</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <div class="project-checkbox">
                <span *ngIf="liabilityInsuranceImg">
                  <button (click)="liabilityInsuranceImg = null"></button>
                </span>
                <input type="file" id="file16" accept="image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" (change)="onFileChanged($event, 'liablity', inputFile3)" [disabled]="liabilityInsuranceImg"  #inputFile3>
                <label for="file16" [hidden]="liabilityInsuranceImg">
                  <i class="icon icon24_24" icon-upload></i>
                  Liability Insurance
                </label>
                <label for="file16" *ngIf="liabilityInsuranceImg">
                  {{liabilityInsuranceImg.name}}
                </label>
                <div *ngIf="!liabilityInsuranceImg && formSubmitAttempt" class="invalid-feedback">
                  <div>Please upload liability insurance image</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group">
              <div class="project-checkbox">
                <span *ngIf="stateRegistrationImg">
                  <button (click)="stateRegistrationImg = null"></button>
                </span>
                <input type="file" id="file18" accept="image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" (change)="onFileChanged($event, 'state', inputFile4)" [disabled]="stateRegistrationImg" #inputFile4>
                <label for="file18" [hidden]="stateRegistrationImg">
                  <i class="icon icon24_24" icon-upload></i>
                  State Registration
                </label>
                <label for="file18" *ngIf="stateRegistrationImg">
                  {{stateRegistrationImg.name}}
                </label>
                <div *ngIf="!stateRegistrationImg && formSubmitAttempt" class="invalid-feedback">
                  <div>Please upload state registration image</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="d-flex flex-wrap w-100 justify-content-center mt-20">
        <div class="checkbox">
          <input type="checkbox" id="check" formControlName="termsAndCondition"
            [ngClass]="{ 'is-invalid': getForm.termsAndCondition.touched && formSubmitAttempt && getForm.termsAndCondition.errors }">
          <label for="check">I have read and accepted &nbsp; <a [routerLink]="['/terms-and-conditions']" class="tc-check"
              (click)="closeModal('custom-modal-1')"> terms and conditions</a></label>
          <div *ngIf="getForm.termsAndCondition.errors && getForm.termsAndCondition.touched && formSubmitAttempt"
            class="invalid-feedback text-center">
            <div *ngIf="getForm.termsAndCondition.errors.required">Please accept read terms and condition</div>
          </div>
        </div>
        <button type="submit" (click)="onSubmit()" class="btn">Register</button>
      </div>
    </form>
  </div>
</custom-modal>