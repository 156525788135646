import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ContactUs } from './models/contactUs.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {


  constructor(private http: HttpClient) {}

  public registerContractor(data: any) {
    return this.http
      .post<any>(`${environment.api.baseUrl}contractor/register`, data)
      .pipe();
  }

  public contactUs(data: ContactUs) {
    return this.http
      .post<any>(`${environment.api.baseUrl}pr-contactuses`, data)
      .pipe();
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }
  
  public getServicesWithoutpagination() {
    return this.http
      .get<any>(`${environment.api.baseUrl}prservices`)
      .pipe(map((response: any) => {
        response.data = response.data.reverse();
        return response;
      }));
  }

  public getPrivacyPolicy() {
    return this.http
      .get<any>(`${environment.api.baseUrl}cms/PRIVACY_POLICY`)
      .pipe(retry(1));
  }

  public getTC() {
    return this.http
      .get<any>(`${environment.api.baseUrl}cms/TC`)
      .pipe(retry(1));
  }

  public getFaqList(search: any) {
    const queryParam = 'list' + '?search=' + search;
    return this.http
      .get<any>(`${environment.api.baseUrl}pr-faqs/${queryParam}`)
      .pipe(retry(1));
  }
  public get(search: any) {
    const queryParam = 'list' + '?search=' + search;
    return this.http
      .get<any>(`${environment.api.baseUrl}pr-faqs/${queryParam}`)
      .pipe(retry(1));
  }

  public get_client_testimonials() {
    return this.http
      .get<any>(`${environment.api.baseUrl}pr-client-testimonials`)
      .pipe(retry(1));
  }
  public get_team_members() {
    return this.http
      .get<any>(`${environment.api.baseUrl}pr-team-members`)
      .pipe(retry(1));
  }
}
